<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-input v-model="inputValue" placeholder="请输入商品名称" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="goodsName" label="商品名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="goodsCategory" label="商品分类" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="商品主图" show-overflow-tooltip>
						<template slot-scope="scope">
							<a :href="fileUrl + scope.row.goodsImage" target="_blank">查看</a>
						</template>
					</el-table-column>
					<el-table-column prop="goodsPrice" label="建议价格" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.status === 'Valid'" style="color: #62c462">正常</i>
              				<i v-else style="color: #ff4343">禁用</i>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="60px">
						<template slot-scope="scope">
							<i class="el-icon-edit-outline m_edit" @click="showUpdate(scope.row)" title="编辑"></i>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" title="修改" :visible.sync="dialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="form" :model="form" label-width="auto" :rules="rules" style="padding-right: 10px">
					<el-form-item label="商品名称">
						<el-input v-model="form.name" disabled></el-input>
					</el-form-item>
					<el-form-item label="商品类别">
						<el-cascader :options="goodscatasData" :show-all-levels="false" :props="cascaderProps" v-model="form.cata" disabled></el-cascader>
					</el-form-item>
					<el-form-item label="商品原价">
						<el-input v-model="form.manuprc" disabled></el-input>
					</el-form-item>
					<el-form-item label="建议价格">
						<el-input v-model="form.price" disabled></el-input>
					</el-form-item>
					<el-form-item label="本地售价" prop="localprc">
						<el-input v-model="form.localprc"></el-input>
					</el-form-item>
					<el-form-item label="商品描述">
						<el-input v-model="form.descr" disabled></el-input>
					</el-form-item>
					<el-form-item label="是否新品">
						<el-select v-model="form.isNew" placeholder="请选择" clearable disabled>
							<el-option label="是" :value="true"> </el-option>
							<el-option label="否" :value="false"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否加热">
						<el-select v-model="form.isHeat" placeholder="请选择" clearable disabled>
							<el-option label="是" :value="true"> </el-option>
							<el-option label="否" :value="false"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="加热秒数" v-if="form.isHeat">
						<el-input v-model="form.heatCnt" disabled></el-input>
					</el-form-item>
					<el-form-item label="允许线上销售">
						<el-select v-model="form.isOnline" placeholder="请选择" clearable disabled>
							<el-option label="是" :value="true"> </el-option>
							<el-option label="否" :value="false"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="允许线下销售">
						<el-select v-model="form.isOffline" placeholder="请选择" clearable disabled>
							<el-option label="是" :value="true"> </el-option>
							<el-option label="否" :value="false"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="商城链接">
						<el-input v-model="form.onlineUrl" disabled></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="form.note" disabled></el-input>
					</el-form-item>

					<el-form-item label="状态">
						<el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" active-value="Valid" inactive-value="Invalid" disabled>
						</el-switch>
					</el-form-item>
					<el-form-item label="商品主图">
						<img v-if="form.img" :src="fileUrl + form.img" class="avatar" />
					</el-form-item>
					<el-form-item label="广告图">
						<img v-if="form.advimg" :src="fileUrl + form.advimg" class="avatar" />
					</el-form-item>
					<el-form-item label="商品图片">
						<div style="display: flex;">
							<img v-if="pictureList3.length>0" v-for="item in pictureList3" :src=" item.url" class="avatar" style="margin: 4px;" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="save" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="dialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE } from "@/utils/config";
	import {
		goodsSelect,
		goodsInsert,
		goodsUpdate,
		goodsDelete,
		goodscatasTree,
		goodsAreaPrcie
	} from "@/api/goods";
	import { fileUpLoadUrl } from "@/api/base.js";
	import { XHR_URL } from "@/utils/config";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			return {
				total: 0,
				currentPage: 1,
				inputValue: null,
				tableData: [],
				map: null,
				dialogVisible: false,
				rules: {
					localprc: [{
						required: true,
						message: "本地售价不能为空",
						trigger: "blur"
					}]
				},
				form: {
					name: "",
					cata: "",
					manuprc: "",
					price: "",
					localprc: "",
					descr: "",
					isNew: "",
					isHeat: 0,
					heatCnt: "",
					isOnline: "",
					isOffline: "",
					onlineUrl: "",
					note: "",
					status: "",
					img: "",
					advimg: "",
					imgs: [],
				},
				pictureList1: [],
				pictureList2: [],
				pictureList3: [],
				goodscatasData: [],
				cascaderProps: {
					children: "children",
					label: "name",
					value: "id",
					checkStrictly: true,
				},
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl() {
				return XHR_URL;
			},
			token() {
				return this.$store.getters.token;
			},
			fileUpLoadUrl() {
				return fileUpLoadUrl;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
				};
			},
		},
		components: {
			mPagination,
		},
		created() {
			this.select();
			this.goodscatasTree();
		},
		mounted() {},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.select();
			},
			showUpdate(data) {
				this.initForm();
				this.form = {
					id: data.goodsId,
					name: data.goodsName,
					cata: data.goodsCategoryId,
					manuprc: data.goodsManuPrice,
					price: data.goodsPrice,
					localprc: data.goodsLocalPrice,
					descr: data.goodsDescr,
					isNew: data.goodsNew,
					isHeat: data.goodsHeating,
					heatCnt: data.heatingSeconds,
					isOnline: data.isOnline,
					isOffline: data.isOffline,
					onlineUrl: data.onlineUrl,
					note: data.note,
					status: data.status,
					img: data.goodsImage,
					advimg: data.goodsAdvImage,
					imgs: data.goodsImages.map((item) => {
						return {
							url: this.fileUrl + item.url,
							name: item.index,
						};
					}),
				};
				this.pictureList3 = this.form.imgs;
				this.dialogVisible = true;
			},
			initForm() {
				if(this.$refs.form) {
					this.$refs.form.resetFields();
				}
				this.form = {
					name: "",
					cata: "",
					manuprc: "",
					price: "",
					localprc: "",
					descr: "",
					isNew: "",
					isHeat: "",
					heatCnt: 0,
					isOnline: "",
					isOffline: "",
					onlineUrl: "",
					note: "",
					status: "",
					img: "",
					advimg: "",
					imgs: [],
				};
			},
			save() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						this.update();
					} else {
						return false;
					}
				});
			},
			handleTreeData(data) {
				data.forEach((item) => {
					if(item.children.length !== 0) {
						this.handleTreeData(item.children);
					} else {
						item.children = null;
					}
				});
			},
			async select() {
				const res = await goodsSelect({
					name: this.inputValue,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async update() {
				const res = await goodsAreaPrcie({
					goodsId:this.form.id,
					localPrice:this.form.localprc,
					partnerId:0
					
				});
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async goodscatasTree() {
				const res = await goodscatasTree({
					name: this.inputValue,
				});
				const {
					pageCount,
					results
				} = res;
				this.handleTreeData(results[0].children);
				this.goodscatasData = results[0].children;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>