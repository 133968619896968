import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询商品类型
export const goodscatasSelect = data => request(`${XHR_URL}/api/v1/goodscatas`, data, "GET");
// 查询商品类型树
export const goodscatasTree = data => request(`${XHR_URL}/api/v1/goodscatas/tree`, data, "GET");
// 新增商品类型
export const goodscatasInsert = data => request(`${XHR_URL}/api/v1/goodscatas`, data, "POST");
// 修改商品类型
export const goodscatasUpdate = data => request(`${XHR_URL}/api/v1/goodscatas`, data, "PUT");
// 删除商品类型
export const goodscatasDelete = data => request(`${XHR_URL}/api/v1/goodscatas`, data, "DELETE");


// 查询商品
export const goodsSelect = data => request(`${XHR_URL}/api/v1/goods`, data, "GET");
// 新增商品
export const goodsInsert = data => request(`${XHR_URL}/api/v1/goods`, data, "POST");
// 修改商品
export const goodsUpdate = data => request(`${XHR_URL}/api/v1/goods`, data, "PUT");
// 删除商品
export const goodsDelete = data => request(`${XHR_URL}/api/v1/goods`, data, "DELETE");
// 修改商品区域价格
export const goodsAreaPrcie = data => request(`${XHR_URL}/api/v1/localprices`, data, "POST");